
.practical-container {
    height: calc(100% - 40px);
    background: #F2F2F2;
    padding: 20px 0;
.practical-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 1200px;
    margin: 0 auto;
}
}
::v-deep .el-breadcrumb {
    margin-bottom: 20px;
    .el-breadcrumb__inner {
        color: #000;
    }
}
.practical-list {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: #fff;
    ::v-deep .el-table--border td:first-child .cell {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 233px;
        height: 100px;
        margin: 0 auto;
        padding: 0;
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}
.course-list {
    margin: 20px;
}
.pages-center {
    margin-bottom: 20px;
}
